import React from 'react';
import qs from 'querystring';

export default ({ alt, coords, zoom }) => (
  <img
    alt={alt}
    src={`https://maps.googleapis.com/maps/api/staticmap?${qs.stringify({
      size: '600x400',
      zoom,
      center: `${coords.lat},${coords.lng}`,
      markers: `${coords.lat},${coords.lng}`,
      key: process.env.GOOGLE_MAPS_API_KEY
    })}`}
  />
);
