import React from 'react';

import warehouses from '../data/warehouses.json';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Button from '../components/Button';
import Map from '../components/Map';

import MapImage from '../images/hong-kong-map.svg';

import styles from './locations.module.scss';

function renderLocation(warehouse) {
  return (
    <div key={warehouse.id} className={styles.location}>
      <div>
        <header>{warehouse.name}</header>
        <div className={styles.address}>{warehouse.address.zh}</div>
        <div className={styles.address}>{warehouse.address.en}</div>
        <div className={styles.button}>
          <Button to={`/book?warehouse=${warehouse.id}`}>立即訂倉</Button>
        </div>
      </div>
      <div className={styles.map}>
        <Map coords={warehouse.coords} zoom={17} />
      </div>
    </div>
  );
}

const LocationsPage = props => (
  <Layout location={props.location} className={styles.root}>
    <SEO title="分店網絡" />
    <section className={styles.lead}>
      <div className={styles.wrapper}>
        <div className={styles.hero}>
          <MapImage width={null} height={null} />
        </div>
        <div className={styles.text}>
          <header>強大儲存網絡</header>
          <p>Roomyr 擁有龐大儲存網絡及經驗豐富的管理層，是您最可靠安心的選擇。</p>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <div className={styles.num}>1,962</div>
              <div className={styles.name}>儲存單位</div>
            </div>
            <div className={styles.stat}>
              <div className={styles.num}>12</div>
              <div className={styles.name}>網絡分店</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={styles.locations}>
      <div className={styles.wrapper}>{warehouses.map(renderLocation)}</div>
    </section>
  </Layout>
);

export default LocationsPage;
